import React, { useEffect, useState } from "react";
import "./LoanRequests.css";

const CACHE_EXPIRATION_TIME = 50000; // 1 minute

const LoanRequests = () => {
  const [loanRequests, setLoanRequests] = useState([]);
  const [collectorNames, setCollectorNames] = useState({});
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isCollectionPopupOpen, setIsCollectionPopupOpen] = useState(false); // New state for collection popup
  const [currentLoan, setCurrentLoan] = useState(null);
  const [editFormData, setEditFormData] = useState({
    loanType: '',
    approval_status: '',
    amount: '',
    interestRate: '',
    duration: '',
    createdAt: '',
  });
  const [availableCollectors, setAvailableCollectors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [collections, setCollections] = useState([]); // New state for collections data
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const [loanIdFilter, setLoanIdFilter] = useState(""); // Filter by Loan ID
  const [collectorFilter, setCollectorFilter] = useState(""); // Filter by Collector Name
  const [collectorName, setCollectorName] = useState("");


  const standardizeDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const calculateLoanEndDate = (createdAt, duration) => {
    const startDate = new Date(createdAt);
    startDate.setMonth(startDate.getMonth() + duration);
    const day = String(startDate.getDate()).padStart(2, "0");
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const year = startDate.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatNumber = (num) => {
    const numberString = num.toString().split('.');
    const lastThree = numberString[0].slice(-3);
    const otherNumbers = numberString[0].slice(0, -3);
    const formattedNumber = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastThree;
    return numberString.length > 1 ? formattedNumber + '.' + numberString[1] : formattedNumber;
  };

  const calculateTotalAmount = (amount, interestRate, duration) => {
    const principal = parseFloat(amount);
    const rate = parseFloat(interestRate);
    const time = parseFloat(duration);
  
    if (isNaN(principal) || isNaN(rate) || isNaN(time)) {
      console.error("Invalid inputs provided to calculateTotalAmount");
      return 0;
    }
  
    const monthlyRate = rate / 100;
    const totalInterest = principal * monthlyRate * time;
    const totalAmount = principal + totalInterest;
  
    return parseFloat(totalAmount.toFixed(2));
  };

  useEffect(() => {
    const fetchLoanRequests = async () => {
      const cachedData = JSON.parse(localStorage.getItem("loanRequestsCache"));
      const now = Date.now();

      if (cachedData && now - cachedData.timestamp < CACHE_EXPIRATION_TIME) {
        setLoanRequests(cachedData.data);
        fetchCollectorNames(cachedData.data);
      } else {
        try {
          const response = await fetch(
            "https://srishticooperativesociety.com/api/loan_requests"
          );
          const data = await response.json();
          setLoanRequests(data);

          localStorage.setItem(
            "loanRequestsCache",
            JSON.stringify({ data, timestamp: now })
          );

          fetchCollectorNames(data);
        } catch (error) {
          console.error("Error fetching loan requests:", error);
        }
      }
    };

    fetchLoanRequests();
    fetchAllCollectors();
  }, []);

  const fetchLoanRequests = async () => {
    const cachedData = JSON.parse(localStorage.getItem("loanRequestsCache"));
    const now = Date.now();

    if (cachedData && now - cachedData.timestamp < CACHE_EXPIRATION_TIME) {
      setLoanRequests(cachedData.data);
      fetchCollectorNames(cachedData.data);
    } else {
      try {
        const response = await fetch(
          "https://srishticooperativesociety.com/api/loan_requests"
        );
        const data = await response.json();
        setLoanRequests(data);

        localStorage.setItem(
          "loanRequestsCache",
          JSON.stringify({ data, timestamp: now })
        );

        fetchCollectorNames(data);
      } catch (error) {
        console.error("Error fetching loan requests:", error);
      }
    }
  };

  const fetchCollectorNames = (loans) => {
    const uniqueCollectorIds = [...new Set(loans.map((loan) => loan.collector_assigned))];

    uniqueCollectorIds.forEach(async (id) => {
      const cachedCollector = JSON.parse(localStorage.getItem(`collector_${id}`));
      const now = Date.now();

      if (cachedCollector && now - cachedCollector.timestamp < CACHE_EXPIRATION_TIME) {
        setCollectorNames((prev) => ({ ...prev, [id]: cachedCollector.name }));
      } else {
        try {
          const response = await fetch(
            `https://srishticooperativesociety.com/api/admins/${id}`
          );
          const data = await response.json();
          setCollectorNames((prev) => ({ ...prev, [id]: data.name }));

          localStorage.setItem(
            `collector_${id}`,
            JSON.stringify({ name: data.name, timestamp: now })
          );
        } catch (error) {
          console.log('200')
        }
      }
    });
  };

  const fetchAllCollectors = async () => {
    const cachedCollectors = JSON.parse(localStorage.getItem("allCollectorsCache"));
    const now = Date.now();

    if (cachedCollectors && now - cachedCollectors.timestamp < CACHE_EXPIRATION_TIME) {
      setAvailableCollectors(cachedCollectors.data);
    } else {
      try {
        const response = await fetch(
          "https://srishticooperativesociety.com/api/admins"
        );
        const data = await response.json();
        setAvailableCollectors(data);

        localStorage.setItem(
          "allCollectorsCache",
          JSON.stringify({ data, timestamp: now })
        );
      } catch (error) {
        console.error("Error fetching all collectors:", error);
      }
    }
  };

  const fetchLoanCollections = async (loanId, collectorAssigned) => {
    try {
      console.log("Collector Assigned:", collectorAssigned);
  
      // Fetch loan collections from the API
      const response = await fetch(
        `https://srishticooperativesociety.com/api/collections/loan/${loanId}`
      );
      const data = await response.json();
  
      if (data && Array.isArray(data)) {
        // Filter collections by status = 'accepted'
        const acceptedCollections = data.filter(collection => collection.status === 'accepted');
        setCollections(acceptedCollections);
        
        // Store collector name and open popup
        const collectorName = collectorNames[collectorAssigned] || "N/A";
     
        setCollectorName(collectorName);
       
        
        setIsCollectionPopupOpen(true);
      } else {
        console.error("Error: Invalid collection data received", data);
      }
    } catch (error) {
      console.error("Error fetching loan collections:", error);
    }
  };
  

  const handleEdit = (loan) => {
    setCurrentLoan(loan);
  
    // Extract the date part (YYYY-MM-DD) from the full date-time string
    const formattedDate = new Date(loan.created_at).toISOString().split("T")[0];
  
    setEditFormData({
      loanType: loan.loan_type,
      approval_status: loan.approval_status,
      amount: loan.amount,
      interestRate: loan.interest_rate,
      duration: loan.duration,
      createdAt: formattedDate, // Set the date part for editing
    });
  
    setIsEditPopupOpen(true);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleEditSubmit = async (e) => {
    e.preventDefault();
  
    // Construct the full date-time string
    const fullDateTime = new Date(`${editFormData.createdAt}T00:00:00.000Z`).toISOString();
  
    const snakeCaseData = {
      loan_type: editFormData.loanType,
      approval_status: editFormData.approval_status,
      amount: editFormData.amount,
      interest_rate: editFormData.interestRate,
      duration: editFormData.duration,
      created_at: fullDateTime, // Send the full date-time format
    };
  
    try {
      const response = await fetch(
        `https://srishticooperativesociety.com/api/loan_requests/${currentLoan.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(snakeCaseData),
        }
      );
  
      if (response.ok) {
        const updatedLoan = await response.json();
        
        // Update the loanRequests state
        setLoanRequests((prev) =>
          prev.map((loan) => (loan.id === currentLoan.id ? updatedLoan : loan))
        );
        
        // Clear the localStorage cache to force a fresh fetch
        localStorage.removeItem("loanRequestsCache");
        
        // Re-fetch loan requests after clearing the cache
        fetchLoanRequests();
  
        setIsEditPopupOpen(false);
      } else {
        const errorData = await response.json();
        console.error("Error updating loan:", errorData);
      }
    } catch (error) {
      console.error("Error updating loan:", error);
    }
  };
  
  const openDeletePopup = (loan) => {
    setCurrentLoan(loan);
    setIsDeletePopupOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `https://srishticooperativesociety.com/api/loan_requests/${id}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        setLoanRequests((prev) => prev.filter((loan) => loan.id !== id));
      }
    } catch (error) {
      console.error("Error deleting loan:", error);
    }
    setIsDeletePopupOpen(false);
    // Clear the localStorage cache to force a fresh fetch
    localStorage.removeItem("loanRequestsCache");
        
    // Re-fetch loan requests after clearing the cache
    fetchLoanRequests();
  };

  const closeEditPopup = () => {
    setIsEditPopupOpen(false);
    setEditFormData({
      loanType: '',
      amount: '',
      interestRate: '',
      duration: '',
      createdAt: '',
    });
  };

  const closeDeletePopup = () => {
    setIsDeletePopupOpen(false);
  };

  const closeCollectionPopup = () => {
    setIsCollectionPopupOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredLoans = loanRequests.filter((loan) => {
    const matchesName = `${loan.firstname} ${loan.lastname}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    
    // Ensure loan.id is defined before calling toString
    const matchesLoanId = loan.id?.toString().includes(loanIdFilter); 
  
    // Ensure collectorNames[loan.collector_assigned] is defined before calling toLowerCase
    const matchesCollector = collectorNames[loan.collector_assigned]?.toLowerCase().includes(collectorFilter.toLowerCase());
    
    return matchesName && matchesLoanId && matchesCollector;
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLoans = filteredLoans.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredLoans.length / itemsPerPage);

  const handlePrint = () => {
    const printContent = document.getElementById("collection-popup-content");
    const printWindow = window.open("", "", "width=600,height=400");
  
    // Add custom print styles within the print window
    printWindow.document.write(`
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
            }
  
            #collection-popup-content {
              width: 100%;
              padding: 5px;
              border: 1px solid #ddd;
              box-shadow: none;
              background: #fff;
              page-break-before: always;
              display:flex;
              justify-content:center;
              align-items:center;
              flex-direction:column;
            }

            .total-amount{
            padding:0;
            margin:0;
            margin-bottom:2px;

            }
  
            .popup-heading {
              font-size: 14px;
              padding:0;
              margin-bottom:3px;
              text-align: center;
            }
  
            .total-amount {
              font-size: 8px;
             
              text-align: center;
            }
  
            .collection-list {
              list-style-type: none;
              padding-left: 0;
              display:flex;
              justify-content:center;
              align-items:center;
              flex-direction:column;
            }
  
            .collection-item {
              font-size: 8px;
              padding-bottom:5px
              
            }
  
            .popup-buttons {
              display: none;
            }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
  
    // Ensure the print window is ready and trigger print
    printWindow.document.close();
    printWindow.print();
  };

  const totalAmount = collections.reduce((total, collection) => total + Math.floor(collection.collection_amount), 0);

  return (
    <div className="loan-requests-container">
      <h1 className="title">Loan Requests</h1>
      
       
    {/* Filter Inputs */}
    <div className="filters">
      <input
        type="text"
        placeholder="Search by customer name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
      />
      <input
        type="text"
        placeholder="Filter by Loan ID"
        value={loanIdFilter}
        onChange={(e) => setLoanIdFilter(e.target.value)}
        className="filter-input"
      />
      <input
        type="text"
        placeholder="Filter by Collector Name"
        value={collectorFilter}
        onChange={(e) => setCollectorFilter(e.target.value)}
        className="filter-input"
      />
    </div>
    
      
      <table className="loan-requests-table">
        <thead>
          <tr>
            <th>Sl.No</th>
            <th>Loan ID</th>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Loan Type</th>
            <th>Loan Amount</th>
            <th>Interest Rate</th>
            <th>Amount with Interest</th>
            <th>Loan Approved Date</th>
            <th>Loan End Date</th>
            <th>Collector Assigned</th>
            <th>Approval Status</th>
            {/* <th>BranchID</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
  {currentLoans.map((loan, index) => (
    <tr key={loan.id}>
      <td>{(currentPage - 1) * itemsPerPage + index + 1}</td> {/* Serial Number */}
      <td>{loan.id}</td>
      <td>{loan.firstname} {loan.lastname}</td>
      <td>{loan.phone_no}</td>
      <td>{loan.loan_type}</td>
      <td>₹{formatNumber(loan.amount)}</td>
      <td>{loan.interest_rate}%</td>
      <td>₹{formatNumber(calculateTotalAmount(loan.amount, loan.interest_rate, loan.duration))}</td>
      <td>{standardizeDate(loan.created_at)}</td>
      <td>{calculateLoanEndDate(loan.created_at, loan.duration)} in {loan.duration} Months</td>
      <td>{collectorNames[loan.collector_assigned] || "N/A"}</td>
      <td>{(loan.approval_status)}</td>
      {/* <td>{loan.branch_id}</td> */}
      <td>
        <button onClick={() => handleEdit(loan)}>Edit</button>
        <button onClick={() => openDeletePopup(loan)}>Delete</button>
        <button onClick={() => fetchLoanCollections(loan.id, loan.collector_assigned, loan.firstname)}>View Collections</button>
      </td>
    </tr>
  ))}
</tbody>
      </table>

      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
{/* Edit Popup */}
{isEditPopupOpen && (
  <div className="popup-overlay">
    <div className="popup-content">
      <h2>Edit Loan Request</h2>
      <form onSubmit={handleEditSubmit}>
      <label>Loan Type</label>
<select
  name="loanType"
  value={editFormData.loanType}
  onChange={handleInputChange}
>
  <option value="Home Loan">Home Loan</option>
  <option value="Car Loan">Car Loan</option>
  <option value="Gold Loan">Gold Loan</option>
  <option value="Personal Loan">Personal Loan</option>
  <option value="Business Loan">Business Loan</option>
</select>

<label>Approval Status</label>
<select
  name="approval_status"
  value={editFormData.approval_status}
  onChange={handleInputChange}
>
  <option value="approved">Accepted</option>
  <option value="rejected">Rejected</option>
  <option value="pending">Pending</option>
</select>
        <label>Amount</label>
        <input
          type="text"
          name="amount"
          value={editFormData.amount}
          onChange={handleInputChange}
        />
        <label>Interest Rate</label>
        <input
          type="text"
          name="interestRate"
          value={editFormData.interestRate}
          onChange={handleInputChange}
        />
        <label>Duration</label>
        <input
          type="text"
          name="duration"
          value={editFormData.duration}
          onChange={handleInputChange}
        />
        <label>Created At</label>
        <input
          type="date" // Use date input type for better handling
          name="createdAt"
          value={editFormData.createdAt}
          onChange={handleInputChange}
        />
        <button type="submit">Submit</button>
        <button type="button" onClick={closeEditPopup}>
          Cancel
        </button>
      </form>
    </div>
  </div>
)}
      {/* Delete Popup */}
      {isDeletePopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Are you sure you want to delete this loan request?</h2>
            <button onClick={() => handleDelete(currentLoan.id)}>Yes</button>
            <button onClick={closeDeletePopup}>No</button>
          </div>
        </div>
      )}
{/* Collection Popup */}{/* Collection Popup */}
{isCollectionPopupOpen && (
  <div className="collection-popup-overlay">
    <div id="collection-popup-content" className="collection-popup-content">
      <h4 className="popup-heading">Loan Collections</h4>
      <div className="total-amount">
      <b> Collected By :{collectorName}</b><br/>
     <b> Total Collection: ₹{totalAmount}</b>
      </div>  
      {/* List of Collections */}
      <ul className="collection-list">
        {collections.map((collection) => (
          <li key={collection.id} className="collection-item">      
            ₹{collection.collection_amount} at {standardizeDate(collection.created_at)}
          </li>
        ))}
      </ul>
      
      {/* Popup Buttons */}
      <div className="popup-buttons">
        <button className="popup-print-btn" onClick={handlePrint}>Print</button>
        <button className="popup-close-btn" onClick={closeCollectionPopup}>Close</button>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default LoanRequests;
