import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaHome,
  FaWallet,
  FaCheckCircle,
  FaCodeBranch,
  FaCogs,
  FaFileAlt,
  FaUserPlus,
  FaUsers,
  FaPiggyBank,
  FaListAlt,
} from 'react-icons/fa';
import './Sidebar.css';

function Sidebar({ isSidebarOpen }) {
  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <ul>
        <li>
          <Link to="/dashboard">
            <FaHome /><span>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link to="/disbursement">
            <FaWallet /><span>Disbursement</span>
          </Link>
        </li>
        <li>
          <Link to="/loanrequests">
            <FaCheckCircle /><span>Loan Approvals</span>
          </Link>
        </li>
        <li>
          <Link to="/branches">
            <FaCodeBranch /><span>Branches</span>
          </Link>
        </li>
        <li>
          <Link to="/variables">
            <FaCogs /><span>Change System Variables</span>
          </Link>
        </li>
        <li>
          <Link to="/apply-for-loan">
            <FaFileAlt /><span>Apply Loan</span>
          </Link>
        </li>
        <li>
          <Link to="/membership-form">
            <FaUserPlus /><span>Membership Form</span>
          </Link>
        </li>
        <li>
          <Link to="/membership-list">
            <FaUsers /><span>Membership List</span>
          </Link>
        </li>
        <li>
          <Link to="/deposit-form">
            <FaPiggyBank /><span>Deposit Form</span>
          </Link>
        </li>
        <li>
          <Link to="/deposit-list">
            <FaListAlt /><span>Deposit List</span>
          </Link>
        </li>

        <li>
          <Link to="/collection-reciept">
            <FaPiggyBank /><span>Collection Reciepts</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
