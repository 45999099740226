import React, { useState } from "react";
import "./MembershipForm.css";

const MembershipForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    fatherHusbandName: "",
    age: "",
    mobileNumber: "",
    aadharNumber: "",
    familyMembers: [{ name: "", relation: "" }],
    address: "",
    livelihood: "",
    societies: [
      {
        name: "",
        amountOfShare: "",
        loanOutstanding: "",
        overdueLoan: "",
        otherDues: "",
      },
    ],
    landDetails: {
      cultivatedLand: "",
      floodAffected: "",
      uncultivable: "",
      doubleCropping: "",
    },
    subscription: "",
    reference: [{ memberName: "", memberNo: "" }],
  });

  const [popup, setPopup] = useState({ show: false, message: "", success: false });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddFamilyMember = () => {
    setFormData({
      ...formData,
      familyMembers: [...formData.familyMembers, { name: "", relation: "" }],
    });
  };

  const handleFamilyMemberChange = (index, field, value) => {
    const newFamilyMembers = [...formData.familyMembers];
    newFamilyMembers[index][field] = value;
    setFormData({ ...formData, familyMembers: newFamilyMembers });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);

    try {
      const response = await fetch("https://extra-api.srishticooperativesociety.com/addMembership.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        setPopup({ show: true, message: "Membership added successfully!", success: true });
      } else {
        setPopup({ show: true, message: result.error || "Something went wrong!", success: false });
      }
    } catch (error) {
      setPopup({ show: true, message: "Network error, please try again.", success: false });
    }
  };

  return (
    <div className="membership-form-container">
      <img src="/images/membershipform.png" alt="Form" />
      <h1>Form for Application for Membership</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name in Full:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Name of Father/Husband:
          <input
            type="text"
            name="fatherHusbandName"
            value={formData.fatherHusbandName}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Age:
          <input
            type="number"
            name="age"
            value={formData.age}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Mobile Number:
          <input
            type="text"
            name="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Aadhar Number:
          <input
            type="text"
            name="aadharNumber"
            value={formData.aadharNumber}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Family Members:
          {formData.familyMembers.map((member, index) => (
            <div key={index} className="family-member-row">
              <input
                type="text"
                placeholder="Name"
                value={member.name}
                onChange={(e) => handleFamilyMemberChange(index, "name", e.target.value)}
              />
              <input
                type="text"
                placeholder="Relation"
                value={member.relation}
                onChange={(e) => handleFamilyMemberChange(index, "relation", e.target.value)}
              />
            </div>
          ))}
          <button type="button" onClick={handleAddFamilyMember}>
            Add Family Member
          </button>
        </label>
        <label>
          Address:
          <textarea
            name="address"
            value={formData.address}
            onChange={handleInputChange}
          ></textarea>
        </label>
        <label>
          Means of Livelihood:
          <input
            type="text"
            name="livelihood"
            value={formData.livelihood}
            onChange={handleInputChange}
          />
        </label>

        <label>
          Subscription:
          <input
            type="text"
            name="subscription"
            value={formData.subscription}
            onChange={handleInputChange}
          />
        </label>

        <label>
          References:
          {formData.reference.map((ref, index) => (
            <div key={index}>
              <input
                type="text"
                placeholder="Member Name"
                value={ref.memberName}
                onChange={(e) => {
                  const newReferences = [...formData.reference];
                  newReferences[index].memberName = e.target.value;
                  setFormData({ ...formData, reference: newReferences });
                }}
              />
              <input
                type="text"
                placeholder="Member No."
                value={ref.memberNo}
                onChange={(e) => {
                  const newReferences = [...formData.reference];
                  newReferences[index].memberNo = e.target.value;
                  setFormData({ ...formData, reference: newReferences });
                }}
              />
            </div>
          ))}
        </label>

        <button type="submit">Submit</button>
      </form>

      {popup.show && (
        <div className={`popup ${popup.success ? 'success' : 'error'}`}>
          <p>{popup.message}</p>
          <button onClick={() => setPopup({ ...popup, show: false })}>Close</button>
        </div>
      )}
    </div>
  );
};

export default MembershipForm;
