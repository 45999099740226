import React, { useState, useEffect } from "react";
import "./Deposit.css";

const DepositForm = ({ depositTypes = ["Fixed Deposit", "Recurring Deposit", "Monthly Return", "Yearly"] }) => {
  const [formData, setFormData] = useState({
    name: "",
    guardian_name: "",
    address: "",
    occupation: "",
    dob: "",
    religion: "",
    aadhar_number: "",
    phone_no: "",
    yearly_income: "",
    nominee_name: "",
    nominee_relation: "",
    deposit_amount: "",
    period: "",
    deposit_type: depositTypes[0], // Default to the first deposit type
  });

  const [closingDate, setClosingDate] = useState(null);
  const [openingDate, setOpeningDate] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to calculate closing date based on period and current date
  const calculateClosingDate = (periodInYears) => {
    if (!periodInYears) return null;

    const today = new Date();
    const closingDate = new Date(today);
    closingDate.setFullYear(today.getFullYear() + parseInt(periodInYears));

    // Account for leap years
    if (today.getMonth() === 1 && today.getDate() === 29 && !((closingDate.getFullYear() % 4 === 0 && closingDate.getFullYear() % 100 !== 0) || closingDate.getFullYear() % 400 === 0)) {
      closingDate.setDate(28); // Adjust to 28th February if the calculated year is not a leap year
    }

    return closingDate.toLocaleDateString();
  };

  // Update closing date and opening date whenever the period changes
  useEffect(() => {
    if (formData.period) {
      const today = new Date();
      setOpeningDate(today.toLocaleDateString()); // Set opening date as today's date
      const newClosingDate = calculateClosingDate(formData.period);
      setClosingDate(newClosingDate);
    }
  }, [formData.period]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch("https://extra-api.srishticooperativesociety.com/addDeposit.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data.status === "success") {
        alert(data.message); // Show success message
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error) {
      alert("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="deposit-form-container">
      <h3>Deposit Form</h3>
      <form onSubmit={handleSubmit}>
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label>Guardian Name:</label>
        <input
          type="text"
          name="guardian_name"
          value={formData.guardian_name}
          onChange={handleChange}
          required
        />

        <label>Address:</label>
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          required
        />

        <label>Occupation:</label>
        <input
          type="text"
          name="occupation"
          value={formData.occupation}
          onChange={handleChange}
          required
        />

        <label>Date of Birth:</label>
        <input
          type="date"
          name="dob"
          value={formData.dob}
          onChange={handleChange}
          required
        />

        <label>Religion:</label>
        <input
          type="text"
          name="religion"
          value={formData.religion}
          onChange={handleChange}
          required
        />

        <label>Aadhar Number:</label>
        <input
          type="text"
          name="aadhar_number"
          value={formData.aadhar_number}
          onChange={handleChange}
          required
        />

        <label>Phone Number:</label>
        <input
          type="text"
          name="phone_no"
          value={formData.phone_no}
          onChange={handleChange}
          required
        />

        <label>Yearly Income:</label>
        <input
          type="text"
          name="yearly_income"
          value={formData.yearly_income}
          onChange={handleChange}
          required
        />

        <label>Nominee Name:</label>
        <input
          type="text"
          name="nominee_name"
          value={formData.nominee_name}
          onChange={handleChange}
          required
        />

        <label>Nominee Relation:</label>
        <input
          type="text"
          name="nominee_relation"
          value={formData.nominee_relation}
          onChange={handleChange}
          required
        />

        <label>Deposit Amount:</label>
        <input
          type="number"
          name="deposit_amount"
          value={formData.deposit_amount}
          onChange={handleChange}
          required
        />

        <label>Period (in years):</label>
        <input
          type="number"
          name="period"
          value={formData.period}
          onChange={handleChange}
          required
        />

{openingDate && <div className="opening-date">Opening Date: {openingDate}</div>}
      {closingDate && <div className="closing-date">Closing Date: {closingDate}</div>}


        <label>Deposit Type:</label>
        <select
          name="deposit_type"
          value={formData.deposit_type}
          onChange={handleChange}
        >
          {depositTypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>

        <button type="submit" className="submit-btn" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
      </form>


      {loading && <div className="loading">Submitting your data...</div>}
    </div>
  );
};

export default DepositForm;
