import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ViewBranch.css';

const formatNumber = (num) => {
  const numberString = num.toString().split('.');
  const lastThree = numberString[0].slice(-3);
  const otherNumbers = numberString[0].slice(0, -3);
  const formattedNumber = otherNumbers
    ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastThree
    : lastThree;
  return numberString.length > 1 ? formattedNumber + '.' + numberString[1] : formattedNumber;
};

const ViewBranch = () => {
  const { id } = useParams();
  const [branchData, setBranchData] = useState(null);
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [totalCollections, setTotalCollections] = useState(0);
  const [filters, setFilters] = useState({
    collectedFrom: '',
    collectedBy: '',
    collectionDate: '',
    branchApprovalStatus: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [collectors, setCollectors] = useState([]); 
  const [showPopup, setShowPopup] = useState(false);
  const [newCollection, setNewCollection] = useState({
    loan_id: '',
    collected_from: '',
    collector_id: '',
    collection_amount: '',
    collection_date: '',
    user_id: 1, // Default value
    branch_id: 1, // Default value
    status: 'accepted', // Default value
  });

  useEffect(() => {
    fetchBranchData();
    fetchCollectionsData();
    fetchCollectorsData();
  }, [id]);

  useEffect(() => {
    applyFilters();
  }, [filters, collections]);

  const fetchBranchData = async () => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/branches/${id}`);
      if (!response.ok) throw new Error('Failed to fetch branch data');
      const branchData = await response.json();
      setBranchData(branchData);
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchCollectionsData = async () => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/collections/branch/${id}`);
      if (!response.ok) throw new Error('Failed to fetch collections data');
      const collectionsData = await response.json();
      setCollections(collectionsData);
      setFilteredCollections(collectionsData);
      calculateTotal(collectionsData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

    // Fetch collector data from the API
    const fetchCollectorsData = async () => {
      try {
        const response = await fetch(`https://srishticooperativesociety.com/api/branch/${id}/collectors`);
        if (!response.ok) throw new Error('Failed to fetch collectors data');
        const collectorsData = await response.json();
        setCollectors(collectorsData);
      } catch (error) {
        setError(error.message);
      }
    };

    const applyFilters = () => {
      let filtered = collections;
    
      // Filter by "Collected From"
      if (filters.collectedFrom) {
        filtered = filtered.filter((item) =>
          item.collected_from.toLowerCase().includes(filters.collectedFrom.toLowerCase())
        );
      }
    
      // Filter by "Collected By"
      if (filters.collectedBy) {
        filtered = filtered.filter((item) =>
          item.collector_name?.toLowerCase().includes(filters.collectedBy.toLowerCase())
        );
      }
    
      // Filter by "Collection Date"
      if (filters.collectionDate) {
        filtered = filtered.filter(
          (item) => item.collection_date.split(" ")[0] === filters.collectionDate
        );
      }
    
      // Filter by "Approval Status"
      if (filters.branchApprovalStatus) {
        filtered = filtered.filter((item) =>
          item.status.toLowerCase().includes(filters.branchApprovalStatus.toLowerCase())
        );
      }
    
      // Filter by "Created At" (specific day)
      if (filters.createdAt) {
        const filterDate = new Date(filters.createdAt).toISOString().split("T")[0]; // Extract YYYY-MM-DD
    
        filtered = filtered.filter((item) => {
          const createdAtDate = new Date(item.created_at).toISOString().split("T")[0]; // Extract YYYY-MM-DD
          return createdAtDate === filterDate;
        });
      }
    
      // Update filtered collections and calculate the total
      setFilteredCollections(filtered);
      calculateTotal(filtered);
    };
    

  const calculateTotal = (data) => {
    const total = data.reduce((sum, item) => sum + parseFloat(item.collection_amount || 0), 0);
    setTotalCollections(total);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const updateCreatedAt = async (collectionId, newDate) => {
    try {
      const formattedDate = new Date(newDate).toISOString().replace('T', ' ').slice(0, 19); // Convert to 'YYYY-MM-DD HH:mm:ss'
      const response = await fetch(`https://srishticooperativesociety.com/api/collections/${collectionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ created_at: formattedDate }),
      });
  
      if (!response.ok) throw new Error('Failed to update created_at');
  
      setCollections((prevCollections) =>
        prevCollections.map((collection) =>
          collection.id === collectionId ? { ...collection, created_at: formattedDate } : collection
        )
      );
      alert('Created_at updated successfully');
    } catch (error) {
      alert('Error updating created_at: ' + error.message);
    }
  };

  const handleAccept = async (collectionId) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/collections/${collectionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: 'accepted' }),
      });

      if (!response.ok) throw new Error('Failed to accept the collection');

      setCollections((prevCollections) =>
        prevCollections.map((collection) =>
          collection.id === collectionId ? { ...collection, status: 'accepted' } : collection
        )
      );
      alert('Collection accepted successfully');
    } catch (error) {
      alert('Error accepting collection: ' + error.message);
    }
  };

  const handlePopupChange = (e) => {
    const { name, value } = e.target;
    setNewCollection({ ...newCollection, [name]: value });
  };

  const handlePopupSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://srishticooperativesociety.com/api/collections', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCollection),
      });

      if (!response.ok) throw new Error('Failed to add collection');
      alert('Collection added successfully!');
      setShowPopup(false);
      fetchCollectionsData(); // Refresh collections
    } catch (error) {
      alert('Error adding collection: ' + error.message);
    }
  };


  const handleReject = async (collectionId) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/collections/${collectionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: 'rejected' }),
      });

      if (!response.ok) throw new Error('Failed to reject the collection');

      setCollections((prevCollections) =>
        prevCollections.map((collection) =>
          collection.id === collectionId ? { ...collection, status: 'rejected' } : collection
        )
      );
      alert('Collection rejected successfully');
    } catch (error) {
      alert('Error rejecting collection: ' + error.message);
    }
  };

  if (loading) {
    return <div className="loader">Loading...</div>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="view-branch-container">
      {branchData && (
        <div className="branch-card">
          <h2>Branch Details</h2>
          <p><strong>Branch Name:</strong> {branchData.branchname}</p>
          <p><strong>Address:</strong> {branchData.address}</p>
          <p><strong>Branch Code:</strong> {branchData.branchcode}</p>
          <p><strong>Created At:</strong> {new Date(branchData.created_at).toLocaleString()}</p>
        </div>
      )}
        <button onClick={() => setShowPopup(true)} className="add-collection-button">Collection By Admin</button>

        {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Add Collection</h3>
            <form onSubmit={handlePopupSubmit}>
              <input type="text" name="loan_id" placeholder="Loan ID" value={newCollection.loan_id} onChange={handlePopupChange} required />
              <input type="text" name="collected_from" placeholder="Collected From" value={newCollection.collected_from} onChange={handlePopupChange} required />
              {/* Dropdown for collector names */}
              <select name="collector_id" value={newCollection.collector_id} onChange={handlePopupChange} required>
                <option value="">Select Collector</option>
                {collectors.map((collector) => (
                  <option key={collector.id} value={collector.id}>
                    {collector.name}
                  </option>
                ))}
              </select>
              <input type="number" step="0.01" name="collection_amount" placeholder="Collection Amount" value={newCollection.collection_amount} onChange={handlePopupChange} required />
              <input type="datetime-local" name="collection_date" value={newCollection.collection_date} onChange={handlePopupChange} required />
              <button type="submit">Submit</button>
              <button type="button" onClick={() => setShowPopup(false)}>Cancel</button>
            </form>
          </div>
        </div>
      )}

      <h2>Collections</h2>
      <div className="filters">
  <div className="filter-item">
    <label htmlFor="collectedFrom">Collected From</label>
    <input
      type="text"
      name="collectedFrom"
      id="collectedFrom"
      placeholder="Filter by Collected From"
      value={filters.collectedFrom}
      onChange={handleFilterChange}
    />
  </div>

  <div className="filter-item">
    <label htmlFor="collectedBy">Collected By</label>
    <input
      type="text"
      name="collectedBy"
      id="collectedBy"
      placeholder="Filter by Collected By"
      value={filters.collectedBy}
      onChange={handleFilterChange}
    />
  </div>

  <div className="filter-item">
    <label htmlFor="collectionDate">System Date</label>
    <input
      type="date"
      name="collectionDate"
      id="collectionDate"
      value={filters.collectionDate}
      onChange={handleFilterChange}
    />
  </div>

  <div className="filter-item">
    <label htmlFor="branchApprovalStatus">Approval Status</label>
    <select
      name="branchApprovalStatus"
      id="branchApprovalStatus"
      value={filters.branchApprovalStatus}
      onChange={handleFilterChange}
    >
     
      <option value="accepted">Accepted</option>
      <option value="rejected">Rejected</option>
      <option value="pending">Pending</option>
    </select>
  </div>

  {/* Filter by Created At (Specific Day) */}
  <div className="filter-item">
    <label htmlFor="createdAt">Collection Deposit Date</label>
    <input
      type="date"
      name="createdAt"
      id="createdAt"
      placeholder="Filter by Created At"
      value={filters.createdAt}
      onChange={handleFilterChange}
    />
  </div>
</div>


      <div className="total-info">
        <p><strong>Total Collections:</strong> ₹{formatNumber(totalCollections.toFixed(2))}</p>
      </div>

      <div className="collections-table">
      <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>LoanID</th>
              <th>Collected From</th>
              <th>Collected By</th>
              <th>System Submit Date</th>
              <th>Collection Deposit Date</th>
              <th>Collection Amount</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredCollections.length === 0 ? (
              <tr>
                <td colSpan="9">No collections available.</td>
              </tr>
            ) : (
              filteredCollections.map((collection, index) => (
                <tr key={collection.id}>
                  <td>{index + 1}</td> {/* Serial Number */}
                  <td>{collection.loan_id}</td>
                  <td>{collection.collected_from}</td>
                  <td>{collection.collector_name}</td>
                  <td>{new Date(collection.collection_date).toLocaleString()}</td>
                  <td>
                    <input
                      type="datetime-local"
                      defaultValue={new Date(collection.created_at).toISOString().slice(0, -1)}
                      onBlur={(e) => {
                        const inputDate = new Date(e.target.value);
                        if (isNaN(inputDate.getTime())) {
                          alert('Invalid date-time format');
                        } else {
                          updateCreatedAt(collection.id, inputDate);
                        }
                      }}
                    />
                  </td>
                  <td>₹{formatNumber(collection.collection_amount)}</td>
                  <td>{collection.status}</td>
                  <td>
                    <button onClick={() => handleAccept(collection.id)} className="accept-button">
                      Accept
                    </button>
                    <button onClick={() => handleReject(collection.id)} className="reject-button">
                      Reject
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewBranch;
