import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const CACHE_KEY = 'dashboardData';
const CACHE_EXPIRY = 2 * 60 * 1000; // 2 minutes

const Dashboard = () => {
  const [totalCollections, setTotalCollections] = useState(0);
  const [totalDisbursement, setTotalDisbursement] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalApprovedAmount, setTotalApprovedAmount] = useState(0);
  const [totalProjectedAmount, setTotalProjectedAmount] = useState(0);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const loadFromCache = () => {
      const cachedData = localStorage.getItem(CACHE_KEY);
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        const isCacheExpired = Date.now() - parsedData.timestamp > CACHE_EXPIRY;

        if (!isCacheExpired) {
          setTotalCollections(parsedData.totalCollections);
          setTotalDisbursement(parsedData.totalDisbursement);
          setTotalProfit(parsedData.totalProfit);
          setTotalApprovedAmount(parsedData.totalApprovedAmount);
          setTotalProjectedAmount(parsedData.totalProjectedAmount);
          setBranches(parsedData.branches);
          setLoading(false);
          return true;
        }
      }
      return false;
    };

    const fetchAndUpdateData = async () => {
      setLoading(true);
      try {
        const collectionsResponse = await fetch('https://srishticooperativesociety.com/api/total_collections');
        const collectionsData = await collectionsResponse.json();
        const totalCollections = parseFloat(collectionsData);

        const approvedLoanResponse = await fetch('https://srishticooperativesociety.com/api/total_approved_loan_amount');
        const approvedLoanData = await approvedLoanResponse.json();
        const totalApprovedAmount = parseFloat(approvedLoanData.total_approved_amount);
        const totalProjectedAmount = parseFloat(approvedLoanData.total_projected_amount);

        const branchesResponse = await fetch('https://srishticooperativesociety.com/api/branches');
        const branchesData = await branchesResponse.json();

        const dashboardData = {
          totalCollections,
          totalDisbursement: 0, // Adjust if you fetch this separately
          totalProfit: totalCollections - totalApprovedAmount,
          totalApprovedAmount,
          totalProjectedAmount,
          branches: branchesData,
          timestamp: Date.now(),
        };

        // Save to state
        setTotalCollections(dashboardData.totalCollections);
        setTotalDisbursement(dashboardData.totalDisbursement);
        setTotalProfit(dashboardData.totalProfit);
        setTotalApprovedAmount(dashboardData.totalApprovedAmount);
        setTotalProjectedAmount(dashboardData.totalProjectedAmount);
        setBranches(dashboardData.branches);

        // Cache data
        localStorage.setItem(CACHE_KEY, JSON.stringify(dashboardData));
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };

    // Load data from cache if valid, else fetch new data
    if (!loadFromCache()) {
      fetchAndUpdateData();
    }
  }, []);

  const handleBranchClick = (branchId) => {
    navigate(`/viewbranch/${branchId}`);
  };

  const randomColor = () => {
    const colors = ['#FFB6C1', '#FF7F50', '#FFD700', '#ADFF2F', '#87CEFA', '#DDA0DD', '#FF6347', '#40E0D0'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const formatNumber = (num) => {
    const numberString = num.toString().split('.');
    const lastThree = numberString[0].slice(-3);
    const otherNumbers = numberString[0].slice(0, -3);
    const formattedNumber = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastThree;
    return numberString.length > 1 ? formattedNumber + '.' + numberString[1] : formattedNumber;
  };

  const schoolsBranches = branches.filter(branch => branch.branchcode.startsWith('SS'));
  const otherBranches = branches.filter(branch => !branch.branchcode.startsWith('SS'));



  return (
    <div className="dashboard-container">
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <h1>Dashboard</h1>
          <div className="cards">
            <div className="card">
              <h2>Total Collections</h2>
              <p>₹{formatNumber(totalCollections.toFixed(2))}</p>
            </div>
            <div className="card">
              <h2>Total Profit (Present)</h2>
              <p>₹{formatNumber(totalProfit.toFixed(2))}</p>
            </div>
            <div className="card">
              <h2>Total Approved Loan Amount</h2>
              <p>₹{formatNumber(totalApprovedAmount.toFixed(2))}</p>
            </div>
            <div className="card">
              <h2>Total Amount After Interest</h2>
              <p>₹{formatNumber(totalProjectedAmount.toFixed(2))}</p>
            </div>
            <div className="card">
              <h2>Total Projected Profit (Future)</h2>
              <p>₹{formatNumber((totalProjectedAmount - totalApprovedAmount).toFixed(2))}</p>
            </div>
            <div className="card">
              <h2>Return on Assets (ROA)</h2>
              <p>{((100 * (totalProjectedAmount - totalApprovedAmount)) / totalApprovedAmount).toFixed(2)}%</p>
            </div>
            <div className="card">
              <h2>Expected Collection Efficiency</h2>
              <p>{((100 * totalCollections) / totalProjectedAmount).toFixed(2)}%</p>
            </div>
          </div>

          <h2>Branches</h2>
          <div className="branches-container">
            {otherBranches.map((branch) => (
              <div
                key={branch.id}
                className="branch-card"
                style={{ backgroundColor: randomColor() }}
                onClick={() => handleBranchClick(branch.id)}
              >
                <h3>{branch.branchname}</h3>
                <p>{branch.address}</p>
                <p>Branch Code: {branch.branchcode}</p>
              </div>
            ))}
          </div>

          <h2>Schools Section</h2>
          <div className="branches-container">
            {schoolsBranches.map((branch) => (
              <div
                key={branch.id}
                className="branch-card"
                style={{ backgroundColor: randomColor() }}
                onClick={() => handleBranchClick(branch.id)}
              >
                <h3>{branch.branchname}</h3>
                <p>{branch.address}</p>
                <p>Branch Code: {branch.branchcode}</p>
              </div>
            ))}
          </div>

         
        </>
      )}
    </div>
  );
};

export default Dashboard;
