import React, { useState, useEffect } from "react";
import "./CollectionReceipt.css";

const CollectionReceipt = () => {
  const [branches] = useState([
    { id: 1, name: "Main Branch" },
    { id: 3, name: "Titabar Branch" },
    { id: 4, name: "Sivasagar" },
    { id: 5, name: "Dibrugarah" },
    { id: 8, name: "Golaghat" },
  ]);

  const [selectedBranch, setSelectedBranch] = useState(null);
  const [collectors, setCollectors] = useState([]);
  const [selectedCollector, setSelectedCollector] = useState(null);
  const [collections, setCollections] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const fetchCollectors = async (branchId) => {
    try {
      const response = await fetch(
        `https://srishticooperativesociety.com/api/branch/${branchId}/collectors`
      );
      const data = await response.json();
      setCollectors(data);
      setSelectedCollector(null);
      setCollections([]);
    } catch (error) {
      console.error("Error fetching collectors:", error);
    }
  };

  const fetchCollections = async (collectorId) => {
    try {
      const response = await fetch(
        `https://srishticooperativesociety.com/api/collections/collector/${collectorId}`
      );
      const data = await response.json();
      setCollections(data);
    } catch (error) {
      console.error("Error fetching collections:", error);
    }
  };

  const handleBranchChange = (e) => {
    const branchId = e.target.value;
    setSelectedBranch(branchId);
    fetchCollectors(branchId);
  };

  const handleCollectorChange = (e) => {
    const collectorId = e.target.value;
    setSelectedCollector(collectorId);
    fetchCollections(collectorId);
  };

  const filterCollections = () => {
    let filtered = collections;
    if (startDate && endDate) {
      filtered = filtered.filter((collection) => {
        const collectionDate = new Date(collection.collection_date);
        return (
          collectionDate >= new Date(startDate) &&
          collectionDate <= new Date(endDate)
        );
      });
    }
    if (statusFilter) {
      filtered = filtered.filter(
        (collection) => collection.status === statusFilter
      );
    }
    return filtered;
  };

  const handlePrint = () => {
    const printContent = document.querySelector(".filtered-collections-table").outerHTML;
  
    // Create a new table with only the desired columns
    const printTable = document.createElement("table");
    printTable.classList.add("print-table");
  
    // Set the table headers for the selected columns
    const tableHeader = `
      <thead>
        <tr>
          <th>Collected From</th>
          <th>Amount</th>
          <th>Date</th>
        </tr>
      </thead>
    `;
    printTable.innerHTML = tableHeader;
  
    // Get the rows and filter out the unwanted columns
    const rows = document.querySelectorAll(".filtered-collections-table tbody tr");
    rows.forEach((row) => {
      const cells = row.querySelectorAll("td");
      // Keep only the Collected From, Amount, and Date columns (index 1, 2, 3)
      const newRow = `
        <tr>
          <td>${cells[1].textContent}</td>
          <td>${cells[2].textContent}</td>
          <td>${cells[3].textContent}</td>
        </tr>
      `;
      printTable.innerHTML += newRow;
    });
  
    // Open the print window and apply styles
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Receipt</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
              color: #333;
            }
            .print-table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
            .print-table th, .print-table td {
              padding: 8px;
              border: 1px solid #ddd;
              text-align: left;
            }
            .print-table th {
              background-color: #f4f4f4;
              font-weight: bold;
            }
            .print-table td {
              background-color: #fff;
            }
            .print-header {
              text-align: center;
              font-size: 1.5rem;
              margin-bottom: 20px;
            }
          </style>
        </head>
        <body>
          <div class="print-header">
            <h1>Collection Receipt</h1>
          </div>
          ${printTable.outerHTML}
        </body>
      </html>
    `);
  
    printWindow.document.close();
    printWindow.print();
  };
  

  const filteredCollections = filterCollections();

  return (
    <div className="collection-receipt-container">
      <h1>Collection Receipt</h1>

      <div className="filter-section">
        <label htmlFor="branch">Select Branch:</label>
        <select id="branch" value={selectedBranch || ""} onChange={handleBranchChange}>
          <option value="" disabled>
            Select Branch
          </option>
          {branches.map((branch) => (
            <option key={branch.id} value={branch.id}>
              {branch.name}
            </option>
          ))}
        </select>

        {collectors.length > 0 && (
          <>
            <label htmlFor="collector">Select Collector:</label>
            <select
              id="collector"
              value={selectedCollector || ""}
              onChange={handleCollectorChange}
            >
              <option value="" disabled>
                Select Collector
              </option>
              {collectors.map((collector) => (
                <option key={collector.id} value={collector.id}>
                  {collector.name}
                </option>
              ))}
            </select>
          </>
        )}

        <div className="date-filter">
          <label htmlFor="start-date">Start Date:</label>
          <input
            type="date"
            id="start-date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />

          <label htmlFor="end-date">End Date:</label>
          <input
            type="date"
            id="end-date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>

        <label htmlFor="status">Filter by Status:</label>
        <select
          id="status"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="">All</option>
          <option value="accepted">Accepted</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>

      <div className="collection-table filtered-collections-table">
        <h2>Collections by</h2>
        {filteredCollections.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Collected From</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredCollections.map((collection) => (
                <tr key={collection.id}>
                  <td>{collection.id}</td>
                  <td>{collection.collected_from}</td>
                  <td>₹{collection.collection_amount}</td>
                  <td>{new Date(collection.collection_date).toLocaleString()}</td>
                  <td>{collection.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No collections available.</p>
        )}
      </div>

      {filteredCollections.length > 0 && (
        <button className="print-button" onClick={handlePrint}>
          Print Receipt
        </button>
      )}
    </div>
  );
};

export default CollectionReceipt;
