import React, { useState, useEffect } from "react";
import "./Deposit.css";
import { formatIndianCurrency, printReceipt, printCertificate } from "./printHelpers";

const DepositList = () => {
  const [deposits, setDeposits] = useState([]);
  const [fetchingDeposits, setFetchingDeposits] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [interestRate, setInterestRate] = useState(0);
  const [filters, setFilters] = useState({
    name: "",
    date: "",
    deposit_type: "",
  });

  const [filteredDeposits, setFilteredDeposits] = useState([]);
  const [totalDeposit, setTotalDeposit] = useState(0);

  useEffect(() => {
    // Fetch all deposits
    fetch("https://extra-api.srishticooperativesociety.com/getAllDeposits.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setDeposits(data.data);
          setFilteredDeposits(data.data);
          calculateTotalDeposit(data.data);
        } else {
          setErrorMessage("No deposits available.");
        }
        setFetchingDeposits(false);
      })
      .catch(() => {
        setErrorMessage("An error occurred while fetching deposits.");
        setFetchingDeposits(false);
      });

    // Fetch the deposit interest rate
    fetch("https://srishticooperativesociety.com/api/variables/4")
      .then((response) => response.json())
      .then((data) => {
        if (data && data.variable_name === "deposit_interest_rate") {
          setInterestRate(Number(data.value));
        }
      })
      .catch(() => {
        setErrorMessage("An error occurred while fetching the interest rate.");
      });
  }, []);

  const calculateTotalDeposit = (deposits) => {
    const total = deposits.reduce(
      (sum, deposit) => sum + Number(deposit.deposit_amount),
      0
    );
    setTotalDeposit(total);
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    const date = new Date(dateString);
    return date.toLocaleString("en-IN", options);
  };

  const handleFilterChange = async (e) => {
    const { name, value } = e.target;
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters);

    // Filter deposits based on updated filters
    const filtered = deposits.filter((deposit) => {
      const matchesName = updatedFilters.name
        ? deposit.name.toLowerCase().includes(updatedFilters.name.toLowerCase())
        : true;
      const matchesDate = updatedFilters.date
        ? deposit.created_at.startsWith(updatedFilters.date)
        : true;
      const matchesType = updatedFilters.deposit_type
        ? deposit.deposit_type === updatedFilters.deposit_type
        : true;

      return matchesName && matchesDate && matchesType;
    });

    setFilteredDeposits(filtered);
    calculateTotalDeposit(filtered);
  };

  return (
    <div className="deposit-list-container">
      <h2>Deposit List</h2>

      <div className="filters">
        <input
          type="text"
          name="name"
          placeholder="Filter by Name"
          value={filters.name}
          onChange={handleFilterChange}
        />
        <input
          type="date"
          name="date"
          placeholder="Filter by Date"
          value={filters.date}
          onChange={handleFilterChange}
        />
        <select
          name="deposit_type"
          value={filters.deposit_type}
          onChange={handleFilterChange}
        >
          <option value="">All Types</option>
          <option value="Fixed Deposit">Fixed Deposit</option>
          <option value="Recurring Deposit">Recurring Deposit</option>
          <option value="Monthly Return">Monthly Return</option>
          <option value="Yearly">Yearly</option>
        </select>
      </div>

      <div className="total-deposit">
        <h2>Total Deposit: ₹{formatIndianCurrency(totalDeposit)}</h2>
        <h4>Interest Rate: {interestRate}% (Fixed for all, 2025)</h4>
      </div>

      {fetchingDeposits ? (
        <p>Loading deposits...</p>
      ) : errorMessage ? (
        <div className="error-message">{errorMessage}</div>
      ) : (
        <table className="deposit-table">
          <thead>
            <tr>
              <th>Account No.</th>
              <th>Name</th>
              <th>Guardian Name</th>
              <th>Deposit Amount</th>
              <th>Date</th>
              <th>Deposit Amount with Interest</th>
              <th>Deposit Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredDeposits.map((deposit) => (
              <tr key={deposit.id}>
                <td>{deposit.id}</td>
                <td>{deposit.name}</td>
                <td>{deposit.guardian_name}</td>
                <td>₹{formatIndianCurrency(deposit.deposit_amount)}</td>
                <td>{formatDate(deposit.created_at)}</td>
                <td>
                  ₹
                  {formatIndianCurrency(
                    Number(deposit.deposit_amount) +
                      (interestRate / 100) * Number(deposit.deposit_amount)
                  )}
                </td>
                <td>{deposit.deposit_type}</td>
                <td>
                  <button
                    className="action-btn"
                    onClick={() => printReceipt(deposit)}
                  >
                    Print Receipt
                  </button>
                  <button
                    className="action-btn"
                    onClick={() => printCertificate(deposit)}
                  >
                    Deposit Certificate
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DepositList;
