import React, { useEffect, useState } from "react";
import "./MembershipList.css";

const MembershipList = () => {
  const [members, setMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    fatherHusbandName: "",
    age: "",
    mobileNumber: "",
    aadharNumber: "",
    address: "",
    livelihood: "",
    subscription: "",
    familyMembers: [],
    reference: [],
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    fetch("https://extra-api.srishticooperativesociety.com/showMembers.php")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setMembers(data);
          setFilteredMembers(data); // Initialize filtered members
        } else {
          console.error("Unexpected response format:", data);
        }
      })
      .catch((error) => console.error("Error fetching members:", error));
  }, []);

    const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Filter asynchronously
    setFilteredMembers(
      members.filter((member) =>
        member.name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleEditClick = (member) => {
    setSelectedMember(member);
    setFormData({
      name: member.name,
      fatherHusbandName: member.father_husband_name,
      age: member.age,
      mobileNumber: member.mobile_number,
      aadharNumber: member.aadhar_number,
      address: member.address,
      livelihood: member.livelihood,
      subscription: member.subscription,
      familyMembers: member.family_members,
      reference: member.reference,
    });
    setIsEditModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFamilyMemberChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFamilyMembers = [...formData.familyMembers];
    updatedFamilyMembers[index][name] = value;
    setFormData({ ...formData, familyMembers: updatedFamilyMembers });
  };

  const handleReferenceChange = (index, e) => {
    const { name, value } = e.target;
    const updatedReference = [...formData.reference];
    updatedReference[index][name] = value;
    setFormData({ ...formData, reference: updatedReference });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedMember = {
      id: selectedMember.id,
      name: formData.name,
      fatherHusbandName: formData.fatherHusbandName,
      age: formData.age,
      mobileNumber: formData.mobileNumber,
      aadharNumber: formData.aadharNumber,
      address: formData.address,
      livelihood: formData.livelihood,
      subscription: formData.subscription,
      familyMembers: formData.familyMembers,
      reference: formData.reference,
    };

    // Make the PUT request to update the member
    fetch(`https://extra-api.srishticooperativesociety.com/editMember.php?id=${selectedMember.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedMember),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Membership updated successfully") {
          alert("Member updated successfully!");
          setIsEditModalOpen(false);
          // Optionally, refresh the members list after update
          setMembers((prevMembers) =>
            prevMembers.map((member) =>
              member.id === selectedMember.id ? updatedMember : member
            )
          );
        }
      })
      .catch((error) => console.error("Error updating member:", error));
  };
  const handlePrintForm = (member) => {
    const printWindow = window.open("", "", "width=400,height=600");
  
    // Write CSS styles directly into the print window
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Member Form</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              background-color: #f4f4f4;
              color: #333;
              margin: 0;
              padding: 20px;
            }
            h1 {
              color: #313296;
              text-align: center;
            }
            h2, h3 {
              color: #962d3e;
            }
            p {
              font-size: 1rem;
              line-height: 1.4;
            }
            .section-title {
              font-weight: bold;
              margin-top: 20px;
              font-size: 1.2rem;
            }
            .member-info {
              margin-bottom: 10px;
            }
          </style>
        </head>
        <body style='padding:30px'>
        <img src='/images/membershipform.png' width='100%'/>
          <h1>Member Form Details</h1>
          
          <h2>Personal Information</h2>
          <p class="member-info"><span class="section-title">Name:</span> ${member.name}</p>
          <p class="member-info"><span class="section-title">Account Number:</span> ${member.id}</p>
          <p class="member-info"><span class="section-title">Father/Husband Name:</span> ${member.father_husband_name}</p>
          <p class="member-info"><span class="section-title">Age:</span> ${member.age}</p>
          <p class="member-info"><span class="section-title">Mobile Number:</span> ${member.mobile_number}</p>
          <p class="member-info"><span class="section-title">Aadhar Number:</span> ${member.aadhar_number}</p>
          <p class="member-info"><span class="section-title">Address:</span> ${member.address}</p>
          <p class="member-info"><span class="section-title">Livelihood:</span> ${member.livelihood}</p>
          <p class="member-info"><span class="section-title">Subscription:</span> ${member.subscription}</p>
          
          <h3>Family Members</h3>
          ${member.family_members.map((family, index) => {
            return `<p class="member-info"><span class="section-title">Family Member ${index + 1}:</span> ${family.name} - ${family.relation}</p>`;
          }).join("")}
  
          <h3>References</h3>
          ${member.reference.map((ref, index) => {
            return `<p class="member-info"><span class="section-title">Reference ${index + 1}:</span> ${ref.name} - ${ref.relation}</p>`;
          }).join("")}
  
        </body>
      </html>
    `);
  
    printWindow.document.close();
    printWindow.print();
  };

  const handleReceiptDownload = async (member) => {
    try {
      const response = await fetch(
        "https://srishticooperativesociety.com/api/variables/5"
      );
      const receiptData = await response.json();
  
      const receiptHTML = `
        <html>
          <head>
            <title>Receipt - Srishti Cooperative Society</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
                color: #313131;
                display:flex;
                justify-content:center;
                align-items:center;
                flex-direction:column;
              }
              .receipt-container {
                max-width: 600px;
                margin: 0 auto;
                border: 1px solid #ddd;
                border-radius: 8px;
                padding: 20px;
                display:flex;
                justify-content:center;
                align-items:center;
                flex-direction:column;
              }
              .logo {
                text-align: center;
                margin-bottom: 20px;
              }
              .logo img {
                width: 250px;
              }
              h1 {
                text-align: center;
                color: #313296;
                margin-bottom: 10px;
                font-size:15px;
              }
              .info {
                font-size: 12px;
                margin-bottom: 10px;
                line-height: 1.6;
              }
              .info p {
                margin: 8px 0;
              }
              .thank-you {
                text-align: center;
                margin-top: 10px;
                font-size: 12px;
                color: #555;
                font-weight: bold;
              }
              .footer {
                text-align: center;
                margin-top: 20px;
                font-size: 10px;
                color: #777;
              }
            </style>
          </head>
          <body>
            <div class="receipt-container">
              <div class="logo">
                <img src="/images/logo.png" alt="Srishti Cooperative Society Logo" />
              </div>
              <h1>Membership Reciept</h1>
              <div class="info">
                <p><strong>Reciept ID:</strong> ${receiptData.id}</p>
                <p><strong>Name:</strong> ${member.name}</p>
                <p><strong>Amount Paid:</strong> ₹${receiptData.value}</p>
                <p><strong>Date:</strong> ${new Date().toLocaleDateString()}</p>
              </div>
              <p class="thank-you">Thank you for your subscription!</p>
              <div class="footer">
                Srishti Cooperative Society<br />
                Empowering communities through cooperation.
              </div>
            </div>
          </body>
        </html>
      `;
  
      // Open the receipt in a new tab for printing
      const printWindow = window.open("", "_blank");
      printWindow.document.write(receiptHTML);
      printWindow.document.close();
      printWindow.print();
    } catch (error) {
      console.error("Error fetching receipt:", error);
    }
  };
  
  

  return (
    <div className="membership-list-container">
      <h1 className="title">Membership List</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>
      {filteredMembers.length > 0 ? (
        <table className="membership-table">
          <thead>
            <tr>
              <th>Account</th>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {filteredMembers.map((member) => (
              <tr key={member.id}>
                <td>{member.id}</td>
                <td>{member.name}</td>
                <td>{member.mobile_number}</td>
                <td>
                  <button className="edit-button" onClick={() => handleEditClick(member)}>
                    Edit
                  </button>
                  <button className="print-button" onClick={() => handlePrintForm(member)}>
                    Print Form
                  </button>

                  <button
                    className="receipt-button"
                    onClick={() => handleReceiptDownload(member)}
                  >
                    Print Receipt
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="no-members">No members found.</p>
      )}

      {isEditModalOpen && (
        <div className="overlay">
          <div className="modal">
            <h2>Edit Member</h2>
            <form onSubmit={handleSubmit}>
              <label>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <label>Father/Husband Name:</label>
              <input
                type="text"
                name="fatherHusbandName"
                value={formData.fatherHusbandName}
                onChange={handleChange}
                required
              />
              <label>Age:</label>
              <input
                type="number"
                name="age"
                value={formData.age}
                onChange={handleChange}
                required
              />
              <label>Mobile Number:</label>
              <input
                type="text"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                required
              />
              <label>Aadhar Number:</label>
              <input
                type="text"
                name="aadharNumber"
                value={formData.aadharNumber}
                onChange={handleChange}
                required
              />
              <label>Address:</label>
              <textarea
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
              <label>Livelihood:</label>
              <input
                type="text"
                name="livelihood"
                value={formData.livelihood}
                onChange={handleChange}
                required
              />
              <label>Subscription:</label>
              <input
                type="text"
                name="subscription"
                value={formData.subscription}
                onChange={handleChange}
                required
              />

              <label>Family Members:</label>
              {formData.familyMembers.map((family, index) => (
                <div key={index}>
                  <input
                    type="text"
                    name="name"
                    value={family.name}
                    onChange={(e) => handleFamilyMemberChange(index, e)}
                    placeholder="Member Name"
                    required
                  />
                  <input
                    type="text"
                    name="relation"
                    value={family.relation}
                    onChange={(e) => handleFamilyMemberChange(index, e)}
                    placeholder="Relation"
                    required
                  />
                </div>
              ))}

              <label>References:</label>
              {formData.reference.map((ref, index) => (
                <div key={index}>
                  <input
                    type="text"
                    name="name"
                    value={ref.name}
                    onChange={(e) => handleReferenceChange(index, e)}
                    placeholder="Referee Name"
                    required
                  />
                  <input
                    type="text"
                    name="relation"
                    value={ref.relation}
                    onChange={(e) => handleReferenceChange(index, e)}
                    placeholder="Relation"
                    required
                  />
                </div>
              ))}

              <div className="modal-buttons">
                <button type="submit">Save Changes</button>
                <button type="button" onClick={() => setIsEditModalOpen(false)}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default MembershipList;
