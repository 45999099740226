export const formatIndianCurrency = (amount) => {
  if (isNaN(amount)) return "Invalid amount";

  const num = amount.toString().replace(/,/g, "");
  const [integer, decimal] = num.split(".");
  let integerPart = integer;

  if (integer.length > 3) {
    const lastThreeDigits = integer.slice(-3);
    const remainingDigits = integer.slice(0, integer.length - 3);
    integerPart = remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThreeDigits;
  }

  return decimal ? `${integerPart}.${decimal}` : integerPart;
};

export const printReceipt = (deposit) => {
  const printContent = `
    <div style="font-family: 'Courier New', monospace; width: 55mm; font-size: 12px; padding: 10px; border: 1px dashed black;">
      <div style="text-align: center; border-bottom: 1px solid black; padding-bottom: 5px; margin-bottom: 5px;">
        <h3 style="margin: 0; font-size: 16px;">Receipt</h3>
        <p style="margin: 0;">(Thank you for your deposit!)</p>
      </div>
      <p><strong>Date:</strong> ${new Date().toLocaleDateString()}</p>
      <p><strong>Name:</strong> ${deposit.name}</p>
      <p><strong>Account Number:</strong> ${deposit.id}</p>
      <p><strong>Deposit Amount:</strong> ₹${formatIndianCurrency(deposit.deposit_amount)}</p>
      <div style="text-align: center; margin-top: 10px;">
        <p>Thank you for banking with us!</p>
        <p style="font-size: 10px;">This is a computer-generated receipt.</p>
      </div>
    </div>
  `;
  const printWindow = window.open("", "", "width=200,height=400");
  printWindow.document.write(printContent);
  printWindow.document.close();
  printWindow.print();
};

export const printCertificate = (deposit) => {
  const printContent = `
    <div style="font-family: 'Times New Roman', serif; width: 210mm; height: 297mm; padding: 20mm; border: 2px solid #000; position: relative;">
      <div style="text-align: center; margin-bottom: 30px;">
      <img src='/images/membershipform.png' width='100%'/>
        <h1 style="margin: 0; font-size: 36px;">Certificate of Deposit</h1>
        <p style="margin: 0; font-size: 18px;">Awarded to</p>
        <h2 style="margin: 10px 0; font-size: 28px;">${deposit.name}</h2>
      </div>
      <p style="font-size: 18px;"><strong>Account Number:</strong> ${deposit.id}</p>
      <p style="font-size: 18px;"><strong>Guardian Name:</strong> ${deposit.guardian_name}</p>
      <p style="font-size: 18px;"><strong>Address:</strong> ${deposit.address}</p>
      <p style="font-size: 18px;"><strong>Occupation:</strong> ${deposit.occupation}</p>
      <p style="font-size: 18px;"><strong>Date of Birth:</strong> ${deposit.dob}</p>
      <p style="font-size: 18px;"><strong>Religion:</strong> ${deposit.religion}</p>
      <p style="font-size: 18px;"><strong>Aadhar Number:</strong> ${deposit.aadhar_number}</p>
      <p style="font-size: 18px;"><strong>Phone No:</strong> ${deposit.phone_no}</p>
      <p style="font-size: 18px;"><strong>Yearly Income:</strong> ₹${formatIndianCurrency(deposit.yearly_income)}</p>
      <p style="font-size: 18px;"><strong>Nominee Name:</strong> ${deposit.nominee_name}</p>
      <p style="font-size: 18px;"><strong>Nominee Relation:</strong> ${deposit.nominee_relation}</p>
      <p style="font-size: 18px;"><strong>Deposit Type:</strong> ${deposit.deposit_type}</p>
      <p style="font-size: 18px;"><strong>Deposit Amount:</strong> ₹${formatIndianCurrency(deposit.deposit_amount)}</p>
      <div style="position: absolute; bottom: 20mm; left: 20mm; right: 20mm; text-align: center;">
        <p style="margin: 0; font-size: 14px;">Authorized Signature</p>
        <div style="width: 100%; height: 2px; background: #000; margin: 10px 0;"></div>
        <p style="margin: 0; font-size: 12px;">This is a computer-generated certificate and does not require a signature.</p>
      </div>
    </div>
  `;
  const printWindow = window.open("", "", "width=900,height=1200");
  printWindow.document.write(printContent);
  printWindow.document.close();
  printWindow.print();
};
